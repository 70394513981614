.watchlist {
  @apply px-12 py-8 bg-primary-carbonBlue;
}

.watchlist__container {
  flex-grow: 1;
  display: grid;
}

@media (min-width: 1280px) {
  .watchlist__container {
    grid-template-columns: 1fr auto;
    grid-gap: 24px;
  }
}
