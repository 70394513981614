.overview {
  @apply px-12 pt-8 bg-primary-carbonBlue;
}

.overview__container {
  flex-grow: 1;
  display: grid;
}

@media (min-width: 1280px) {
  .overview__container {
    grid-template-columns: 1fr auto;
    grid-gap: 24px;
  }
}

.overview__stats {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 32px;
}
