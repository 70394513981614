.collectionHeader {
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.collectionHeader::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 0;
}
